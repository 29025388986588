import { PREVIEW_QUERY_PARAMS } from '@config';
import type {
  PartialGlobalFeatureFlags,
  PartialInternalFeatureFlags,
} from '@contexts/feature-flags/feature-flags.types';
import { ProductVariantAvailability } from '@vcc-www/federated-graph-types';
import type { ReadonlyURLSearchParams } from 'next/navigation';

type handleQueryParamStringUpdateProps = {
  queryParamHasFlags: boolean;
  updatedFlagsKeys: string[];
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  updatedFlagsValues: any[];
};

let overrideFlagsQueryParamStr = 'overrideFlags=';

function queryParamHasFlags(overrideFlagsQueryParam: string) {
  const overrideFlagsQueryParamValues = overrideFlagsQueryParam.split('=');
  return overrideFlagsQueryParamValues[1] !== '';
}

function handleQueryParamStringUpdate({
  queryParamHasFlags,
  updatedFlagsKeys,
  updatedFlagsValues,
}: handleQueryParamStringUpdateProps) {
  updatedFlagsKeys.forEach((key, index) => {
    // 1) Handles when the query param string already has the flag
    const updatedFlagRegExp = RegExp(`(?:[^a-zA-Z0-9-])^|${key}-([^.]*)`, 'g');
    const updatedFeatureFlagFromQueryStr =
      overrideFlagsQueryParamStr.match(updatedFlagRegExp) ?? [];
    const updatedFeatureFlagFromQueryStrKeyVal =
      updatedFeatureFlagFromQueryStr?.[0]?.split('-');

    if (updatedFeatureFlagFromQueryStrKeyVal) {
      const updatedFeatureFlagFromQueryStrVal =
        updatedFeatureFlagFromQueryStrKeyVal[1];

      // 1.1) Handles value changes of an already appended flag
      if (
        updatedFeatureFlagFromQueryStrVal &&
        updatedFeatureFlagFromQueryStrVal !== `${updatedFlagsValues[index]}`
      ) {
        const updatedVal =
          typeof updatedFlagsValues[index] === 'object'
            ? JSON.stringify(updatedFlagsValues[index])
            : updatedFlagsValues[index];

        overrideFlagsQueryParamStr = overrideFlagsQueryParamStr.replace(
          updatedFeatureFlagFromQueryStr[0] ?? '',
          `${key}-${updatedVal}`,
        );
      }
      return;
    }

    // 2) Handles separation when there are flags in the query param string
    if (index === 0 && queryParamHasFlags) {
      overrideFlagsQueryParamStr += '.';
    }

    // 3) Appending flag to query param string
    const updatedVal =
      typeof updatedFlagsValues[index] === 'object'
        ? JSON.stringify(updatedFlagsValues[index])
        : updatedFlagsValues[index];

    overrideFlagsQueryParamStr += `${index !== 0 ? '.' : ''}${key}-${updatedVal}`;

    // 4) Handles separation when there are more flags to append in the query param string
    const lastItem = updatedFlagsKeys.length - 1 === index;
    if (!lastItem) {
      overrideFlagsQueryParamStr += '.';
    }
  });
}

export function handleOverrideFeatureFlagsToQueryStr({
  searchParams,
  updatedFeatureFlags,
  updatedGlobalFeatureFlags,
}: {
  searchParams: ReadonlyURLSearchParams;
  updatedFeatureFlags: PartialInternalFeatureFlags | null;
  updatedGlobalFeatureFlags: PartialGlobalFeatureFlags | null;
}) {
  const updatedFeatureFlagsKeys = updatedFeatureFlags
    ? Object.keys(updatedFeatureFlags)
    : [];
  const updatedGlobalFeatureFlagsKeys = updatedGlobalFeatureFlags
    ? Object.keys(updatedGlobalFeatureFlags)
    : [];

  const hasChanges =
    updatedFeatureFlagsKeys.length > 0 ||
    updatedGlobalFeatureFlagsKeys.length > 0;

  if (!hasChanges) {
    return null;
  }

  const existingOverriddenFeatureFlags = searchParams.get(
    PREVIEW_QUERY_PARAMS.overrideFlags,
  );
  let overrideFlagsQueryParamHasFlags = queryParamHasFlags(
    overrideFlagsQueryParamStr,
  );

  if (!overrideFlagsQueryParamHasFlags && !!existingOverriddenFeatureFlags) {
    overrideFlagsQueryParamStr += existingOverriddenFeatureFlags;
    overrideFlagsQueryParamHasFlags = true;
  }

  const updatedFeatureFlagsValues = Object.values(updatedFeatureFlags ?? {});
  handleQueryParamStringUpdate({
    queryParamHasFlags: overrideFlagsQueryParamHasFlags,
    updatedFlagsKeys: updatedFeatureFlagsKeys,
    updatedFlagsValues: updatedFeatureFlagsValues,
  });

  const updatedGlobalFeatureFlagsValues = Object.values(
    updatedGlobalFeatureFlags ?? {},
  );
  handleQueryParamStringUpdate({
    queryParamHasFlags: overrideFlagsQueryParamHasFlags,
    updatedFlagsKeys: updatedGlobalFeatureFlagsKeys,
    updatedFlagsValues: updatedGlobalFeatureFlagsValues,
  });

  return overrideFlagsQueryParamStr;
}

export function parseOverrideAvailableAtValue(
  value: string,
): ProductVariantAvailability | null {
  if (!value) return null;
  if (
    Object.values(ProductVariantAvailability).includes(
      value as ProductVariantAvailability,
    )
  ) {
    return value as ProductVariantAvailability;
  }
  return null;
}
